import React from 'react'
import './Story.css'
import 'font-awesome/css/font-awesome.css'
import {Link} from 'react-router-dom'
import pic from './1.jpg'
import OwlCarousel from 'react-owl-carousel'
import pic1 from './home-4-img-2-590.76923076923x768.jpg'
import pic2 from './home-4-img-3-590.76923076923x768.jpg'
import pic3 from './home-4-img-4-590.76923076923x768.jpg'
import pic4 from './home-4-img-19c-590.76923076923x768.jpg'
import pic5 from './home-4-img-19b-590.76923076923x768.jpg'
import pic6 from './home-4-img-19a-590.76923076923x768.jpg'
import pic7 from './home-4-img-5-590.76923076923x768.jpg'
import pic8 from './home-4-img-6-590.76923076923x768.jpg'
import pic9 from './home-4-img-20c-590.76923076923x768.jpg'
import pic10 from './home-4-img-20a-590.76923076923x768.jpg'
import pic11 from './home-4-img-20b-590.76923076923x768.jpg'
import pic12 from './home-4-img-7-590.76923076923x768.jpg'
import pic13 from './home-4-img-8-590.76923076923x768.jpg'
import pic14 from './home-4-img-9-590.76923076923x768.jpg'
import pic15 from './home-4-img-10-590.76923076923x768.jpg'
import pic16 from './home-4-img-11-590.76923076923x768.jpg'
import pic17 from './home-4-img-12-590.76923076923x768.jpg'
import pic18 from './copy.jpg'
import pic19 from './home-4-img-14-590.76923076923x768.jpg'
import pic20 from './home-4-img-15-590.76923076923x768.jpg'
import pic21 from './home-4-img-16-590.76923076923x768.jpg'
import pic22 from './home-4-img-23-590.76923076923x768.jpg'
import pic23 from './home-4-img-17-590.76923076923x768.jpg'
import pic24 from './home-4-img-21c-590.76923076923x768.jpg'
import pic25 from './home-4-img-21b-590.76923076923x768.jpg'
import pic26 from './home-4-img-21a-590.76923076923x768.jpg'

class Story extends React.Component {
    

    render() {
        return (
            <div className="story">
             <div className="navigation">
           <div className="container">
               <div className="row">
               <span><Link to="/">Home</Link></span>
           <span>/  NEW SEASON OUTFITS</span>
           <h2>New Season Outfits</h2>
               </div>

           </div>
       </div>
       {/* pictures */}
       <div className="container-fluid">
           <div className="row">
              
               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 first-img hover">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pos">
                        <img src={pic}/>
                        <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>

                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pos">
                    <img src={pic1}/>
                    <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pos">
                    <img src={pic2}/>
                    <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pos">
                    <img src={pic3}/>
                    <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                    </div>

                </div>
               </div>


               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 slider-story">
            <OwlCarousel
            items='1'
            nav
            loop
            >
            <div className="item">
                <div className="pic">
                <img src={pic4} alt=""/>
                </div>
                <div className="sub">
            <h3>Gold Black Bracelet</h3>
            <button>See Whole Collection</button>
     </div>
               
            </div>
            <div className="item">
            <div className="pic">
            <img src={pic5} alt=""/>
            </div>
            <div className="sub">
            <h3>Gold Bracelet</h3>
            <button>See Whole Collection</button>
     </div>
                
            </div>
            <div className="item">
            <div className="pic">
            <img src={pic6} alt=""/>
            </div>
            <div className="sub">
            <h3>Gold Necklace</h3>
            <button>See Whole Collection</button>
     </div>
            </div>



            </OwlCarousel>


            </div>


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 first-col hover">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 pos">
                   
                    <img src={pic7} alt=""/>
                    <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
           
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 pos">
               
                    <img src={pic8} alt=""/>
                    <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
           
                </div>

            </div>
            </div>

          
           </div>
           {/* colem two */}
           <div id="space1">
            <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 slider-story">
            <OwlCarousel
            items='1'
            nav
            loop
            >
            <div className="item">
                <div className="pic">
                <img src={pic9} alt=""/>
                </div>
                <div className="sub">
            <h3>Gold Black Bracelet</h3>
            <button>See Whole Collection</button>
     </div>
               
            </div>
            <div className="item">
            <div className="pic">
            <img src={pic10} alt=""/>
            </div>
            <div className="sub">
            <h3>Gold Bracelet</h3>
            <button>See Whole Collection</button>
     </div>
                
            </div>
            <div className="item">
            <div className="pic">
            <img src={pic11} alt=""/>
            </div>
            <div className="sub">
            <h3>Gold Necklace</h3>
            <button>See Whole Collection</button>
     </div>
            </div>



            </OwlCarousel>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 second-col hover">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic12} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic13} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic14} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic15} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic16} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic17} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>


            </div>


            </div>
            </div>
            </div>
 {/* colem three */}
 <div className="row">
          

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 second-col hover">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic18} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic19} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic20} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic21} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic22} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pos">
                <img src={pic23} alt=""/>
                <div className="mark">
                            <h6>Elipe</h6>
                            <span>style</span>
                            <p>$450.00</p>
                            <ul>
                            <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                </div>


            </div>


            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 slider-story">
            <OwlCarousel
            items='1'
            nav
            loop
            >
            <div className="item">
                <div className="pic">
                <img src={pic24} alt=""/>
                </div>
                <div className="sub">
            <h3>Gold Black Bracelet</h3>
            <button>See Whole Collection</button>
     </div>
               
            </div>
            <div className="item">
            <div className="pic">
            <img src={pic25} alt=""/>
            </div>
            <div className="sub">
            <h3>Gold Bracelet</h3>
            <button>See Whole Collection</button>
     </div>
                
            </div>
            <div className="item">
            <div className="pic">
            <img src={pic26} alt=""/>
            </div>
            <div className="sub">
            <h3>Gold Necklace</h3>
            <button>See Whole Collection</button>
     </div>
            </div>



            </OwlCarousel>
            </div>
            </div>



       </div>

              
            </div>
        )
    }
}

export default Story
