import React from 'react'
import OwelCarousel from 'react-owl-carousel'
import pic1 from '../slider/1.jpg'
import pic2 from '../slider/2.jpg'
import pic3 from '../slider/3.jpg'
import pic4 from '../slider/5.jpg'
import pic5 from '../slider/6.jpg'
import './Header.css'
export default class Header extends React.Component {
   

    render() {
        return (
            <div className="header">
                
                <div className="container-middle">
                    <div className="row">
                        <div className="slider col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                            
                            

                            
                         <OwelCarousel
                        items="1"
                        
                        nav
                        dots
                        loop
                        >
    <div className="item">
    <div className="pic">
     <img src={pic1} alt="" srcset=""/>
     
      </div>
      <div className="sub">
            <h1>BEST DAY</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.<br/> Laborum, molestias a fugit.</p>
            <button>view more</button>
     </div>
    </div>
    <div className="item">
    <div className="pic">
     <img src={pic2} alt="" srcset=""/>
    
      </div>
      <div className="sub">
            <h1>FUN TIME</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.<br/> Laborum, molestias a fugit.</p>
            <button>view more</button>
     </div>
    </div>
    <div className="item">
   <div className="pic">
      <img src={pic3} alt="" srcset=""/>
      
       </div>
       <div className="sub">
            <h1>SUN RISE</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.<br/> Laborum, molestias a fugit.</p>
            <button>view more</button>
     </div>
    </div>
    

</OwelCarousel>          

           </div> 

              <div className="mini-slider col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
               <div className="row">
               <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 one">
               <div className="picture">
                <img src={pic4} alt=""/>
               </div>
               <div className="caption">
                <h2>BOY SHOES</h2>
                <p>see whole collection</p>
               </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 two">
               <div className="picture2">
               <img src={pic5} alt=""/>
               </div>
               <div className="caption">
               <h2>Boy Shirt</h2>
                <p>see whole collection</p>
                </div>
               </div>
               </div>



                   </div>

                    </div>

                </div>
                
            </div>
        )
    }
}
