import React from 'react'
import './Pages.css'
import pic from './payments-img-2.jpg'
import CarouselText from '../CarouselText/CarouselText'
class Pages extends React.Component {


    render() {
        return (
            <div>

                <div className="biger-pic">
            <img src={pic} alt="" srcset=""/>
                </div>
                <div style={{clear:'both'}}></div>
                <div className="price-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 box">
                            <div className="price-blue">
                            <span>Basic</span>
                            <h2>$ 12.99<span>/month</span> </h2>
                            <p>Alienum phaedrum torquatos nec eu, vis detra.</p>
                            <ul>
                                <li>Lorem ispum dolor sit</li>
                                <li>Lorem ispum dolor sit</li>
                                <li>Lorem ispum dolor sit</li>
                                <li>Lorem ispum dolor sit</li>
                            </ul>
                            <button>take this offer</button>
                            </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 box">
                            <div className="price-blue" id="red">
                            <span>Basic</span>
                            <h2>$ 12.99<span>/month</span></h2>
                            <p>Alienum phaedrum torquatos nec eu, vis detra.</p>
                            <ul>
                                <li>Lorem ispum dolor sit</li>
                                <li>Lorem ispum dolor sit</li>
                                <li>Lorem ispum dolor sit</li>
                                <li>Lorem ispum dolor sit</li>
                            </ul>
                            <button>take this offer</button>
                            </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 box">
                            <div className="price-blue">
                            <span>Basic</span>
                            <h2>$ 12.99<span>/month</span></h2>
                            <p>Alienum phaedrum torquatos nec eu, vis detra.</p>
                            <ul>
                                <li>Lorem ispum dolor sit</li>
                                <li>Lorem ispum dolor sit</li>
                                <li>Lorem ispum dolor sit</li>
                                <li>Lorem ispum dolor sit</li>
                            </ul>
                            <button>take this offer</button>
                            </div>
                            </div>

                        </div>

                    </div>
                    <CarouselText/>
                </div>
               
                <div style={{clear:'both'}}></div>
               

            </div>
        )
    }
}

export default Pages
