import React from 'react'
import 'font-awesome/css/font-awesome.css'
import './Shop.css'
import {Link} from 'react-router-dom'
import pic from './h-8-prod-9-600x800.jpg'
import pic1 from './h-13-prod-3-600x800.jpg'
import pic2 from './h-13-prod-4-600x800.jpg'
import pic3 from './banner-shop-300x217.jpg'
import pic4 from './shop-single-img-2.jpg'
import pic5 from './shop-single-img-7.jpg'
import pic6 from './shop-single-img-3.jpg'
import pic7 from './shop-single-img-11-600x800.jpg'
import pic8 from './shop-single-img-9.jpg'
import pic9 from './shop-single-img-14-600x800.jpg'
import pic10 from './shop-single-img-8.jpg'
import pic11 from './shop-single-img-6.jpg'
import pic12 from './shop-single-img-4.jpg'
import pic13 from './project-h-product-1-600x800.jpg'
import pic14 from './project-h-product-2-600x800.jpg'
import pic15 from './project-h-product-3-600x800.jpg'
import pic16 from './project-h-product-4-600x800.jpg'
import pic17 from './project-h-product-3-600x800.jpg'
import pic18 from './project-h-product-6-600x800.jpg'


class Shop extends React.Component {

    componentDidMount(){
        
           
}

    render() {
     
       
        return (
            
            
            <div className="all-content">
       <div className="navigation">
           <div className="container">
               <div className="row">
               <span><Link to="/">Home</Link></span>
           <span>/ Shop</span>
           <h3>Shop</h3>
               </div>

           </div>
           

       </div>

<div className="content">
<div className="container">
           <div className="row">
               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 productes">
                <p>SHOWING 1–15 OF 182 RESULTS</p>
                <div id="option">
               
                <select>
                  
                    <option value="sort by popularity one">sort by popularity one</option>
                    <option value="sort by popularity two">sort by popularity two</option>
                    <option value="sort by popularity three">sort by popularity three</option>
                    <option value="sort by popularity foure">sort by popularity foure</option>
                </select>
                </div>

{/* product */}

<div style={{clear:'both'}}></div>
<br/>
                <div className="box-prod">
                   
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 accesory">
                       <div className="inner-pro">
                       <div className="effect">
                        <img src={pic4} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 accesory">
                    <div className="inner-pro">
                    <div className="effect">
                        <img src={pic5} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 accesory">
                    <div className="inner-pro">
                    <div className="effect">
                        <img src={pic6} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 accesory">
                    <div className="inner-pro">
                    <div className="effect">
                        <img src={pic7} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 accesory">
                    <div className="inner-pro">
                    <div className="effect">
                        <img src={pic8} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 accesory">
                    <div className="inner-pro">
                    <div className="effect">
                        <img src={pic9} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 accesory">
                    <div className="inner-pro">
                    <div className="effect">
                        <img src={pic10} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 accesory">
                    <div className="inner-pro">
                        <div className="effect">
                        <img src={pic11} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                      
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       
                       </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 accesory">
                    <div className="inner-pro">
                    <div className="effect">
                        <img src={pic12} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                    </div>

                    </div>
                    <div style={{clear:'both'}}></div>
                   {/* sub prods */}
                   <div className="sub-prods">
                       <div className="row">
                           <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 sub-col">
                           <div className="inner-sub">
                           <div className="effect">
                        <img src={pic13} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Pale Red Sound</h4>
                       <p>$380.00</p>
                       <button>Accesorice</button>
                       </div>
                           </div>
                           <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 sub-col">
                           <div className="inner-sub">
                           <div className="effect">
                        <img src={pic14} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Green Sound</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 sub-col">
                        <div className="inner-sub">
                        <div className="effect">
                        <img src={pic15} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Blue Navy Bag</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div> 
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 sub-col">
                           <div className="inner-sub">
                           <div className="effect">
                        <img src={pic16} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Pale Blue Sound</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                           </div>
                           <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 sub-col">
                           <div className="inner-sub">
                           <div className="effect">
                        <img src={pic17} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Red Sound</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 sub-col">
                        <div className="inner-sub">
                        <div className="effect">
                        <img src={pic18} alt=""/>
                        <ul>
                           <li><a href="#"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-eye" aria-hidden="true"></i></a></li>
                           <li><a href="#"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a></li>
                       </ul>
                        </div>
                       <h4>Deep Blue Sound</h4>
                       <p>$120.00</p>
                       <button>Accesorice</button>
                       </div> 
                        </div>

                       </div>
                       <div className="number">
                           <ul>
                               <li><a href="#">1</a></li>
                               <li><a href="#">2</a></li>
                               <li><a href="#">3</a></li>
                               <li><a href="#">4</a></li>
                               <li><a href="#">...</a></li>
                               <li><a href="#">11</a></li>
                               <li><a href="#">12</a></li>
                               <li><a href="#">13</a></li>
                           </ul>

                       </div>

                   </div>



                    
                </div>
             
              

               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 sidebar">
            <div id="sidebar">
            <h3>Categories</h3>
            <ul>
                <li><a href="#">Accessories (4)</a></li>
                <li><a href="#">Bags (4)</a></li>
                <li><a href="#">Basics (4)</a></li>
                <li><a href="#">Casual (12)</a></li>
                <li><a href="#">Children (8)</a></li>
                <li><a href="#">Clothes (8)</a></li>
                <li><a href="#">Coat (3)</a></li>
                <li><a href="#">Cosmetic (12)</a></li>
                <li><a href="#">Decor (5)</a></li>
                <li><a href="#">Fall (8)</a></li>
                <li><a href="#">Fashion (8)</a></li>
                <li><a href="#">Interior (16)</a></li>
                <li><a href="#">Jacket (3)</a></li>
                <li><a href="#">Kids (5)</a></li>
                <li><a href="#">Lifestyle (7)</a></li>
                <li><a href="#">Organic (10)</a></li>
                <li><a href="#">Showcase (6)</a></li>
                <li><a href="#">Skirts (3)</a></li>
                <li><a href="#">Split (4)</a></li>
                <li><a href="#">Style (21)</a></li>
                <li><a href="#">Summer (5)</a></li>
                <li><a href="#">Sunglasses (3)</a></li>
                <li><a href="#">Tops (3)</a></li>
                <li><a href="#">Urban (4)</a></li>
                <li><a href="#">Watch (16)</a></li>
            </ul>
            </div>
              <div id="sidebar">
                  <h3>Colors</h3>
                  <ul>
                      <li><a href="#">Blue (1)</a></li>
                      <li><a href="#">Red (1)</a></li>
                      <li><a href="#">Yellow (1)</a></li>
                  </ul>

              </div>

            <div className="top-products">
           
            <div className="top-box">
                <img src={pic} alt=""/>
                <h4>Peonies and Roses</h4>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <p>$40.00</p>

            </div>
            <div className="top-box">
                <img src={pic1} alt=""/>
                <h4>Peonies and Roses</h4>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <p>$40.00</p>

            </div>
            <div className="top-box">
                <img src={pic2} alt=""/>
                <h4>Peonies and Roses</h4>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <span><i className="fa fa-star"></i></span>
                <p>$40.00</p>

            </div>


            </div>

{/* search */}

<div className="sidebar-search">
    <input type="search" placeholder="search..."/>
    <button><i className="fa fa-search" aria-hidden="true"></i></button>

</div>
<div className="paragraph">
    <h3>Tags</h3>
    <p>BAGS CLOTHES DECORATION FLOWERS LIFESTYLE LOOK MINIMAL MODERN NEW STYLISH TREND</p>
<img src={pic3} alt=""/>
</div>
<br/><br/>
<h2>Newsletter</h2>
<div className="sidebar-search">

    <input type="search" placeholder="Your Email"/>
    <button><i class="fa fa-envelope-o" aria-hidden="true"></i></button>

</div>


      
      </div>         
</div>
</div>
</div>
            </div>
          
        )
        
    }
}

export default Shop
