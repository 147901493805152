import React from 'react';
import './Newslatter.css'
import pic from './h-8-img-2.jpg'
import pic1 from './h-8-img-3.jpg'
class Newslatter extends React.Component {
    render() {
        return (
            <div className="news">
               <div className="container-middle">
                   <div className="row">
                   <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 cover">
                    <div className="background">
                        <h3>Join our Newsletter</h3>
                        <input type="text" placeholder="your mail"/><br/>
                        <button>send</button>

                    </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 img">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pad">
                        <div className="first-img">
                            <img src={pic} alt=""/>
                                <div className="text">
                                    <h2>Copy the look</h2>
                                    <p>See Whole Collection</p>

                                </div>

                        </div>
                            </div>

                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pad">
                            <div className="first-img">
                            <img src={pic1} alt=""/>
                            <div className="text">
                                    <h2>Copy the look</h2>
                                    <p>See Whole Collection</p>

                                </div>
                            </div>
                            </div>

                        </div>

                    </div>

                   </div>

               </div>
                
            </div>
        );
    }
}

export default Newslatter;