import React from 'react';
import './pro.css'
class Pro extends React.Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <div className="item">
               
                <img src={this.props.pic}/>
                <h6>{this.props.title}</h6>
                <p>${this.props.price}</p>
               
            </div>
        );
    }
}

export default Pro;