import React from 'react'
import {Link} from 'react-router-dom'
import './Blog.css'
import OwelCarousel from 'react-owl-carousel'
import $ from 'jquery'
import 'font-awesome/css/font-awesome.css'
import pic from './author-img-1-300x300.jpg'
import pic1 from './blog-final-img-3-100x100.jpg'
import pic2 from './blog-final-img-2-100x100.jpg'
import pic3 from './blog-final-img-1-100x100.jpg'
import pic4 from './blog-sidebar-img-1-300x217.jpg'
import pic5 from './11.jpg'
import pic6 from './22.jpg'
import pic7 from './blog-list-gallery-post-img-1.jpg'
import pic8 from './user-1-100x100.png'
import pic9 from './user-2-100x100.png'
import pic10 from './user-3-100x100.png'
class Blog extends React.Component {
    
   componentDidMount(){
       $(document).ready(function(){
        $(".blog .blog-carousel .comment .text-comment .question #replay").click(function(){
            $(this).parent().find('.form-footer').fadeIn()
        })
        $(".blog .blog-carousel .comment .text-comment .question #form-btn").click(function(){
            $(this).parent().fadeOut()
        })
       })
   }

    render() {
        return (
            <div className="blog">
               <div className="navigation">
           <div className="container">
               <div className="row">
               <span><Link to="/">Home</Link></span>
           <span>/ Blog</span>
           <h3>Blog</h3>
               </div>

           </div>
           

       </div>
       <div style={{height:'10vh'}}></div>

{/* colums */}
<div className="container" id="container">
    <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 blog-carousel">
<div>
        <OwelCarousel
items="1"
id="blog-slider"
nav
autoplay
loop

>
    <div className="item">
            <img src={pic5} alt=""/>
    </div>
    <div className="item">
            <img src={pic6} alt=""/>
    </div>
    <div className="item">
            <img src={pic7} alt=""/>
    </div>
 
</OwelCarousel>
</div>
<span>AUGUST 24 / LIFESTYLE 3 COMMENTS</span>
            <div className="discription">
               <h2>Elegance is elimination.</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae feugiat magna, ut mattis ligula. Aliquam ut rutrum est. Maecenas sit amet scelerisque orci. Aenean et ex ut elit tincidunt rutrum vitae eleifend metus. Nunc tincidunt venenatis tellus euismod fermentum. Maecenas sed dapibus eros. Phasellus eu mi metus. Nunc mi nisl, viverra id sollicitudin et, auctor sit amet augue. Morbi blandit dolor ac rhoncus semper. Donec rutrum risus vitae arcu interdum condimentum. Pellentesque eu ex metus.</p>
            </div>
            <div className="discription">
               <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing elit. vitae feugiat magna, ut mattis ligula.</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae feugiat magna, ut mattis ligula. Aliquam ut rutrum est. Maecenas sit amet scelerisque orci. Aenean et ex ut elit tincidunt rutrum vitae eleifend metus. Nunc tincidunt venenatis tellus euismod fermentum. Maecenas sed dapibus eros. Phasellus eu mi metus. Nunc mi nisl, viverra id sollicitudin et, auctor sit amet augue. Morbi blandit dolor ac rhoncus semper. Donec rutrum risus vitae arcu interdum condimentum. Pellentesque eu ex metus. Maecenas facilisis est at aliquet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae feugiat magna, ut mattis ligula. Aliquam ut rutrum est. Maecenas sit amet scelerisque orci. Aenean et ex ut elit tincidunt rutrum vitae eleifend metus. Nunc tincidunt venenatis tellus euismod fermentum. Maecenas sed dapibus eros. Phasellus eu mi metus. Nunc mi nisl, viverra id sollicitudin et, auctor sit amet augue. Morbi blandit dolor ac rhoncus semper. Donec rutrum risus vitae arcu interdum condimentum. Pellentesque eu ex metus. Maecenas facilisis est at aliquet. Phasellus eu mi metus. Nunc mi nisl, viverra id sollicitudin et, auctor sit amet augue. Morbi blandit dolor ac rhoncus semper. Donec rutrum risus vitae arcu interdum condimentum. Pellentesque eu ex metus. Maecenas facilisis est at.</p>
            </div>
            <h6 id="text-footer">ELEGANT, MINIMAL, STYLE</h6>
            <h6 id="text-footer2">SHARE :<i className="fa fa-facebook"></i> <i className="fa fa-twitter"></i> <i className="fa fa-telegram"></i></h6>
       <div style={{clear:'both'}}></div>
       <div className="header-comment">
           <img src={pic} alt=""/>
            <div className="comment-text">
                <h2>Camila Smith</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae feugiat mattis ligula. Aliquam ut rutrum est. Maecenas sit amet.</p>
            <span><i className="fa fa-facebook"></i> <i className="fa fa-twitter"></i> <i className="fa fa-telegram"></i></span>
            </div>
       </div>

<div style={{clear:'both'}}></div>
<div className="comment">
    <h2>Comments</h2>
    <div className="text-comment">
<img src={pic8} alt=""/>
<div className="question">
<button id="replay">replay</button>
    <h3>Lisa Atkins</h3>
    <span>AUGUST 28</span>
    <p>Aliquam ut rutrum est. Maecenas sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae feugiat mattis.</p>
    <div style={{clear:'both'}}></div>
<div className="form-footer">
    <button id="form-btn">cancel reaply</button>
    <h2>Leave a Reply</h2>
    <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 form">
            <input type="text" placeholder="Your Name"/>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 form">
        <input type="text" placeholder="Your Family"/>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form">
<input type="text" placeholder="Your Website"/>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form">
    <textarea cols="90" rows="14" placeholder="Your Comment"></textarea>
    <button id="submit">submit</button>
</div>

    </div>
    

</div>
</div>







    </div>

    <div className="text-comment" id="text-comment">
<img src={pic9} alt=""/>
<div className="question">
<button id="replay">replay</button>
    <h3>Helen Doris</h3>
    <span>AUGUST 28</span>
    <p>Maecenas sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae feugiat labore. Aliquam ut rutrum est comino oton</p>
    <div style={{clear:'both'}}></div>
<div className="form-footer">
<button id="form-btn">cancel reaply</button>
    <h2>Leave a Reply</h2>
    <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 form">
            <input type="text" placeholder="Your Name"/>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 form">
        <input type="text" placeholder="Your Family"/>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form">
<input type="text" placeholder="Your Website"/>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form">
    <textarea cols="90" rows="14" placeholder="Your Comment"></textarea>
    <button id="submit">submit</button>
</div>

    </div>
    

</div>
</div>

    </div>

    <div className="text-comment">
<img src={pic10} alt=""/>
<div className="question">
<button id="replay">replay</button>
    <h3>Tina Robertson</h3>
   
    <span>AUGUST 28</span>
    <p>Aliquam ut rutrum est. Ut vitae feugiat mattis. Maecenas sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div style={{clear:'both'}}></div>
<div className="form-footer">
<button id="form-btn">cancel reaply</button>
    <h2>Leave a Reply</h2>
    <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 form">
            <input type="text" placeholder="Your Name"/>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 form">
        <input type="text" placeholder="Your Family"/>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form">
<input type="text" placeholder="Your Website"/>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form">
    <textarea cols="90" rows="14" placeholder="Your Comment"></textarea>
    <button id="submit">submit</button>
</div>

    </div>
    

</div>
</div>

    </div>

</div>
{/* form */}

<div className="form-footer">
    <h2>Leave a Reply</h2>
    <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 form">
            <input type="text" placeholder="Your Name"/>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 form">
        <input type="text" placeholder="Your Family"/>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form">
<input type="text" placeholder="Your Website"/>
</div>
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form">
    <textarea cols="90" rows="14" placeholder="Your Comment"></textarea>
    <button id="submit">submit</button>
</div>

    </div>
    

</div>

       
        </div>

        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 sidebar">

{/* search */}

<div className="sidebar-search">
    <input type="search" placeholder="search..."/>
    <button><i className="fa fa-search" aria-hidden="true"></i></button>

</div>
<div id="image2">
<img src={pic} alt=""/>
</div>
       
             <div id="side-para">
                 <h3>About Author</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae feugiat mattis ligula. Aliquam ut rutrum est. Maecenas sit amet.</p>

             </div>

            <div className="top-products">
           
            <div className="top-box">
                <img src={pic1} alt=""/>
                <h4>Blue Basice</h4>
               
                <p>SEPTEMBER 6</p>

            </div>
            <div className="top-box">
                <img src={pic2} alt=""/>
                <h4>Pink style</h4>
                
                <p>SEPTEMBER 6</p>

            </div>
            <div className="top-box">
                <img src={pic3} alt=""/>
                <h4>Orange is new black</h4>
                <p>SEPTEMBER 6</p>

            </div>


            </div>
            <div id="sidebar">


<h3>Categories</h3>
<ul>
   
    <li><a href="#">Accessories (4)</a></li>
    <li><a href="#">Clothes (3)</a></li>
    <li><a href="#">Fashion (3)</a></li>
    <li><a href="#">Lifestyle (6)</a></li>
    <li><a href="#">Lookbook (12)</a></li>
    <li><a href="#">Travel (3)</a></li>
    <li><a href="#">Uncategorized (1)</a></li>
</ul>
</div>

<div className="paragraph">
    <h3>Tags</h3>
    <p>BAGS CLOTHES DECORATION FLOWERS LIFESTYLE LOOK MINIMAL MODERN NEW STYLISH TREND</p>
    <br/>
    <h2>Newsletter</h2>
<div className="sidebar-search">

    <input type="search" placeholder="Your Email"/>
    <button><i class="fa fa-envelope-o" aria-hidden="true"></i></button>

</div>
<br/><br/><br/>
<img src={pic4} alt=""/>
</div>




        </div>


    </div>

</div>



            </div>
        )
    }
}

export default Blog
