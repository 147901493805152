import React from 'react';
import './App.css';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom'
import Main from './components/Main/Main';
import Pages from './components/pages/Pages';
import Story from './components/story/Story';
import Shop from './components/shop/Shop';
import Blog from './components/Blog/Blog';
import Navbar from './components/navbar/Navbar';
import Navbutton from './components/navButton/Navbutton';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <div className="App">



      
 <Router>

 <Navbar/>
<Navbutton/>

 <Switch>
<Route exact path="/" component={Main}/>
<Route path="/pages" component={Pages}/>
<Route path="/story" component={Story}/>
<Route path="/shop" component={Shop}/>
<Route path="/blog" component={Blog}/>



</Switch>
 



<Footer/>

</Router>


      
    </div>
  );
}

export default App;
