import React from 'react';
import './Navbar.css'
class Navbar extends React.Component {
    render() {
        return (
            <div className="navbar">

                <div className="container-middle">
                    <div className="row">
                        <div className="nav-text">
                        <p>Free shipping on domestic orders over $150</p>
                        </div>
                        <div className="nav-login">
                        <ul>
                        <li><a href="#">SignIn</a></li>
                            <li><a href="#">ContactUS</a></li>
                            <li><a href="#">FAQ</a></li>
                            
                        </ul>
                        </div>


                    </div>

                </div>

                
            </div>
        );
    }
}

export default Navbar;