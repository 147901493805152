import React from 'react';
import { Link } from 'react-router-dom';
import pic from './logo-dark.png'
import './NavButton.css'
import pic2 from './menu.svg'
import pic3 from './search.svg'
import pic4 from './heart.svg'
import pic5 from './add-to-basket.svg'
import pic6 from './1.jpg'
import pic7 from './2.jpg'
import pic8 from './3.jpg'
import pic9 from './4.jpg'
import pic10 from './phone.svg'
import pic11 from './house.svg'
import pic12 from './email.svg'
import pic13 from './speech-bubble.svg'
import pic14 from './menu-img-81.png'
import $ from 'jquery';

class Navbutton extends React.Component {

    componentDidMount() {
        $(document).ready(function () {

            if (window.innerWidth > 1200) {
                $(".menu .cart #menu").click(() => {
                    $(".menu .side").animate({ "right": "0" }, 1000)
                    $(".menu .wrapper").fadeIn(1000)
                })

                $(".menu .side .close a").click(() => {
                    $(".menu .side").animate({ "right": "-100%" }, 1000)
                    $(".menu .wrapper").fadeOut(1000)
                })
            }




            if (window.innerWidth < 1200) {
                $(".menu .cart #menu").click(() => {
                    $(".menu").toggleClass('addmenu')
                    $(".menu .list ul").toggleClass('pos')
                })
            }

        })


        let rtl = document.documentElement
        $(document).scroll(function () {
            if (rtl.scrollTop > 200) {
                $(".menu").css({ 'position': 'fixed', 'top': '0', 'left': '0', 'right': '0', 'z-index': '99999' })
            } else {
                $(".menu").css({ 'position': 'static', 'top': '0', 'left': '0', 'right': '0', 'z-index': '99999' })
            }
        })





    }



    render() {

        return (
            <div className="menu">

                <div className="container-middle">
                    <div className="row">
                        <div className="list col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <span><img src={pic} alt="" /></span>
                            <ul>
                                <li><Link to="/"><span>Home</span> </Link></li>
                                <li id="sub-one"><Link to="/pages"><span>Pages</span></Link></li>
                                <li><Link to="/story"><span>Story</span></Link></li>
                                <li><Link to="/shop"><span>Shop</span></Link></li>
                                <li><Link to="/blog"><span>Blog</span></Link></li>
                               
                            </ul>
                        </div>
                        <div className=" col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 cart">
                            <span><a href="#" id="menu"><img src={pic2} /></a></span>
                            <ul>
                                <li><a href="#"><img src={pic3} /><span>search</span> </a></li>
                                <li><a href="#"><img src={pic4} /><span>whishlist</span></a></li>
                                <li><a href="#"><img src={pic5} /><span>0/$0.00</span></a></li>
                            </ul>
                        </div>

                    </div>


                    {/* sub menu */}
                    {/* <div className="sub1">
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-xl-3">
                            <ul>
                                <li><a href="#">main Home</a></li>
                                <li><a href="#">Category Columns </a></li>
                                <li><a href="#">Furniture Home</a></li>
                                <li><a href="#">Flower Shop</a></li>
                                <li><a href="#">Men’s Fashion</a></li>
         
                            </ul>
                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-3">
                                <ul>
                                    <li><a href="#">Product Showcase</a></li>
                                    <li><a href="#">Watch Store</a></li>
                                    <li><a href="#">Vertical Slider</a></li>
                                    <li><a href="#">Shop Masonry</a></li>
                                    <li><a href="#">Kids Store</a></li>
                                </ul>
                

                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-3">
                            <ul>
                                    <li><a href="#">Sunglasses Store</a></li>
                                    <li><a href="#">Cosmetics Store</a></li>
                                    <li><a href="#">Shop Grid</a></li>
                                    <li><a href="#">Home Décor</a></li>
                                    <li><a href="#">Women’s Fashion</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-3">
                                <img src={pic14} alt=""/>
                            </div>

                        </div>

                    </div> */}








                </div>

                {/* make window right */}
                <div className="wrapper">
                    <div className="side">
                        <div className="container">
                            <div className="row">

                                <div className="inner">
                                    <div className="close">
                                        <a href="#"><span>&times;</span></a>

                                    </div>
                                    <img src={pic} alt="" width="135" />
                                    <p>Time to shop! Find your favorite product, check the latest collection & don’t miss out the best discounts with Onea!</p>
                                </div>
                                <h3>#Instagram</h3>
                                <div className="picture">
                                    <img src={pic6} alt="" />
                                    <img src={pic7} alt="" />
                                    <img src={pic8} alt="" />
                                    <img src={pic9} alt="" />

                                </div>
                                <form>
                                    <label>Newsletter</label>
                                    <input type="text" placeholder="your Email" />
                                    <button><img src={pic12} width="16" /></button>

                                </form>
                                <h4>contactUs</h4>
                                <ul>
                                    <li><img src={pic10} width="15" />&nbsp;<a href="#">156-677-124-442-2887</a></li>
                                    <li><img src={pic11} width="15" />&nbsp;<a href="#">onea@elated-themes.com</a></li>
                                    <li><img src={pic13} width="15" />&nbsp;<a href="#">184 Street Victoria 8007</a></li>
                                </ul>

                            </div>


                        </div>

                    </div>
                </div>


            </div>
        )
    }
}

export default Navbutton
