import React,{useState,useEffect} from 'react'
import './products.css'
import $ from 'jquery'
import Items from '../items/Items';
import Itemses from '../items2/Itemses';
const Products = (props) => {

    const [clk, setclk] = useState([])

    const [cmk,setcmk]=useState([])

useEffect(() => {
  
   
        fetch("https://fakestoreapi.com/products/category/jewelery")
        .then(res=>res.json())
        .then(rsl=>setclk(rsl))

        fetch("https://fakestoreapi.com/products?limit=5")
        .then(res=>res.json())
        .then(rsl=>setcmk(rsl))



        $(document).ready(function(){
       
            $("#clk1").click((e)=>{
                e.preventDefault()
                $(".orginal").fadeIn()
                $(".orginal2").fadeIn()
                $(".products .cart-pro .orginal:last-of-type").css("display","none")
            })
            $("#clk2").click((e)=>{
                e.preventDefault()
                $(".orginal2:odd").fadeOut()
                $(".orginal2:even").fadeIn()
            })
            $("#clk3").click((e)=>{
                e.preventDefault()
                $(".orginal:odd").fadeOut()
                $(".orginal2:odd").fadeIn()
            })

            $("#clk4").click((e)=>{
                e.preventDefault()
                $(".orginal:even").fadeOut()
                $(".orginal:odd").fadeIn()
            })
            $("#clk5").click((e)=>{
                e.preventDefault()
                $(".orginal").fadeOut()
                $(".orginal:even").fadeIn()
            })
            $("#clk6").click((e)=>{
                e.preventDefault()
                $(".orginal2").fadeOut()
                $(".orginal").fadeIn()
            })
      
       
    })
    
    
}, []);





    return (
        <div className="products">
                <div className="titr">
                    <p>SUMMER COLLECTION</p>
                    <h3>Product of the week</h3>

               <ul>
                   <li><a href="#" id="clk1">all</a></li>
                   <li><a href="#" id="clk2">children</a></li>
                   <li><a href="#" id="clk3">accessories</a></li>
                   <li><a href="#" id="clk4">Girls</a></li>
                   <li><a href="#" id="clk5">Shoes</a></li>
                   <li><a href="#" id="clk6">Boys</a></li>
               </ul>

                </div>

                <div className="cart-pro">
                <div className="container-middle">
             <div className="row">
                            <div>
                               {
                                   clk.map((n)=>{
                                       return(
                                            <div className="orginal2">
                                                <Items image={n.image} name={n.title} price={n.price}/>
                                               

                                            </div>

                                       ) 
                                        

                                       
                                       
                                   })
                               }
                               <div style={{clear:'both'}}></div>
                              

{
    cmk.map((n)=>{
        return(
             <div className="orginal">
                 <Itemses image={n.image} name={n.title} price={n.price}/>
                

             </div>

        ) 
         

        
        
    })
}

</div>

                
</div>            
                          

                </div>

                
            </div>
            </div>
    )
}

export default Products;
