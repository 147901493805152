import React from 'react'
import Header from '../slider/Header'
import Carousel from '../carousel/Carousel'
import './Main.css'
import Newslatter from '../newslatter/Newslatter'
import Products from '../products/Products'
import Boxes from '../Boxes/Boxes'
import CarouselText from '../CarouselText/CarouselText'
import FooterBox from '../footerBox/FooterBox'
class Main extends React.Component {
    

    render() {
        return (
            <div className="main">
                <div className="head">
                <Header/>
                </div>
              
              <div className="car">
              <Carousel/>
              </div>
              <div className="news">
                  <Newslatter/>

              </div>
              <div className="cart">
                  <Products/>

              </div>

              <div className="four-box">
                  <Boxes/>

              </div>
              <div className="two-carousel">
                  <CarouselText/>

              </div>
              <div className="box-footer">
                <FooterBox/>
              </div>
             
          
           
            </div>
        )
    }
}

export default Main
