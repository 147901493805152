import React from 'react'
import './CarouselText.css'
import pic from './client-h1.png'
import pic2 from './client-h2.png'
import pic3 from './client-h3.png'
import pic4 from './client-h4.png'
import pic5 from './client-h5.png'
import pic6 from './client-h6.png'
import OwlCarousel from 'react-owl-carousel'

export default  class CarouselText extends React.Component{
    constructor(props){
        super(props)
        this.state={
            products : [],
            responsive:{
                0: {
                    items: 1,
                },
                450: {
                    items: 2,
                },
                600: {
                    items: 3,
                },
                1000: {
                    items: 4,
                },
            },

        }

    }
   

render() {
   


    return (
        <div className="CarouselText">
            <div className="container-middle">
                <div className="row">
                <div className="carousels-text">
                <p>SUMMER COLLECTION</p>
                <h3>What they're saying</h3>

            </div>
            <div className="all">
            <div className="carousel-one">

<OwlCarousel
items="1"
id="carousel-one"
nav
autoplay
loop
>
   <div className="item">
   <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   </p>
    <span>mario<br/>jhonson</span>
   </div>
   <div className="item">
   <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   </p>                <span>mario<br/>jhonson</span>
   </div>
   <div className="item">
   <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   </p>                <span>mario<br/>jhonson</span>
   </div>
   <div className="item">
   <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   </p>                <span>mario<br/>jhonson</span>
   </div>
   <div className="item">
   <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem harum esse nisi quibusdam aut repellat tempora dolore reprehenderit magnam saepe.
   </p>                <span>mario<br/>jhonson</span>
   </div>



</OwlCarousel>
</div>

<div className="carousel-two">
<OwlCarousel
items="5"
id="carousel-two"
autoplay
responsive={this.state.responsive}
loop
>
   <div className="item">
   <img src={pic} alt=""/>
   </div>
   <div className="item">
   <img src={pic2} alt=""/>
   </div>
   <div className="item">
   <img src={pic3} alt=""/>
   </div>
   <div className="item">
   <img src={pic4} alt=""/>
   </div>
   <div className="item">
   <img src={pic5} alt=""/>
   </div>
   <div className="item">
   <img src={pic6} alt=""/>
  </div>
 

</OwlCarousel>
</div>
            </div>
           
                </div>

            </div>
           
        </div>
    )
}
}


