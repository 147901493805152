import React from 'react'
import './Carousel.css'
import OwelCarousel from 'react-owl-carousel'
import Pro from '../pro/Pro'

class Carousel extends React.Component {
    constructor(props){
        super(props)
        this.state={
            prods:[],
            res:""
        }
    }

componentDidMount(){
    fetch('https://fakestoreapi.com/products')
    .then(res=>res.json())
    .then(rsl=>this.setState({
        prods:rsl,
        res:{
         
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                1000:{
                    items:3
                }
            
        }
    }))
}

    render() {
        return (
            <div className="carousel">
<div className="titr">
    <p>SUMMER COLLECTION</p>
    <h2>Product of the week</h2>

</div>

<OwelCarousel
items="3"
id="pro"
nav
autoplay
loop
responsive={this.state.res}
>
  {
  this.state.prods.map(function(n){
    return <Pro pic={n.image} title={n.category} price={n.price}/>
})
}
</OwelCarousel>

</div>
        );
    }
}

export default Carousel;