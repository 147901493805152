import React from 'react'
import './Itemses.css'
const Itemses = (props) => {
    return (
        <div className="test">
             <div className="prodses">
                
                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 dis">
                     
                <img src={props.image} alt=""/>
                <h6>{props.name}</h6>
                <p>${props.price}</p>
                </div>
                </div>
                </div>
         
    )
}

export default Itemses;
