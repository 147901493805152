import React from 'react'
import './Boxes.css'
import pic from './refresh.svg'
import pic2 from './worldwide.svg'
import pic3 from './supermarket-gift.svg'
import pic4 from './cloud.svg'
const Boxes = () => {
    return (
        <div className="box">
            <div className="container-middle">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 boxes">
                        <div className="mini-box">
                        <img src={pic} alt=""/>
                        <h3>Order Tracking</h3>
                        <p>	Order Tracking
                        Lorem ipsum dolor sit amet,
                         consectetuer adipiscing elit.</p>
                        </div>

                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 boxes back">
                    <div className="mini-box">
                        <img src={pic2} alt=""/>
                        <h3>Fast Delivery</h3>
                        <p>	Order Tracking
                        Lorem ipsum dolor sit amet,
                         consectetuer adipiscing elit.</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 boxes">
                    <div className="mini-box">
                        <img src={pic3} alt=""/>
                        <h3>Gift Cards</h3>
                        <p>	Order Tracking
                        Lorem ipsum dolor sit amet,
                         consectetuer adipiscing elit.</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 boxes back">
                    <div className="mini-box">
                        <img src={pic4} alt=""/>
                        <h3>Digital Deals</h3>
                        <p>	Order Tracking
                        Lorem ipsum dolor sit amet,
                         consectetuer adipiscing elit.</p>
                        </div>
                    </div>

                </div>

            </div>
            
        </div>
    )
}

export default Boxes;
