import React from 'react'
import './Footer.css'
import pic from './footer-logo.png'
import pic2 from './footer-image-1.png'
const Footer = () => {
    return (
        <div style={{position:'relative'}}>
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 list">
<img src={pic} alt=""/>
<p>Lorem ipsum dolor sit<br/> amet, doming quaeque an mea gravida.</p>
<img src={pic2} alt=""/>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 list">
<h3>Locate us</h3>
<ul>
    <li><a href="#">487 Eighth Avenue West Street, New York</a></li>
    <li><a href="#">Phone: 0035265244</a></li>
    <li><a href="#">E-mail: info@onea.com</a></li>
</ul>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 list">
<h3>Locate us</h3>
<ul>
    <li><a href="#">About Us</a></li>
    <li><a href="#">FAQ Page</a></li>
    <li><a href="#">Contact Us</a></li>
    <li><a href="#">Work with us</a></li>
    <li><a href="#">Offices</a></li>
</ul>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 list">
                    <h3>Information</h3>
<ul>
    <li><a href="#">Men</a></li>
    <li><a href="#">Woman</a></li>
    <li><a href="#">Home & Living</a></li>
    <li><a href="#">Sale</a></li>
    <li><a href="#">Private Shopping</a></li>
</ul>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 list">
                    <h3>Connect</h3>
<ul>
    <li><a href="#">Follow Us On Instagram</a></li>
    <li><a href="#">Add to Facebook</a></li>
    <li><a href="#">Pinterest</a></li>
    <li><a href="#">Follow us on Twitter</a></li>
    <li><a href="#">Google</a></li>
</ul>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 list">
                    <h3>Download App</h3>
<ul>
    <li><a href="#">Onea App for iOS
and Android</a></li>
    <li><a href="#">Newsletter</a></li>
   
   
</ul>
<input type="text" placeholder="Your Email"/>
                    </div>

                </div>
        <hr style={{backgroundColor:'#ccc',marginTop:'40px'}}/>
        <div className="copyright">
<p><a href="#">&copy;2018 Elated Themes. All Rights Reserved</a></p>
        </div>
            </div>
            
        </div>
        </div>
    )
}

export default Footer;
