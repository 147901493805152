import React from 'react'
import './FooterBox.css'
import pic from './k-h-banner-3.jpg'
import pic2 from './k-h-banner-4.jpg'
import pic3 from './k-h-banner-5.jpg'
import pic4 from './k-h-img-1.png'
import pic5 from './k-h-img-2.png'
import pic6 from './k-h-img-3.png'
const FooterBox = () => {
    return (
        <div className="footerbox">
            <div className="container-middle">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 space">
                    <div className="img-box">
                        <img src={pic} alt=""/>
                        <img src={pic4} alt="" className="img" id="first"/>
                    </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 space">
                    <div className="img-box">
                        <img src={pic2} alt=""/>
                        <img src={pic5} alt="" className="img" id="second"/>
                    </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 space">
                    <div className="img-box">
                        <img src={pic3} alt=""/>
                        <img src={pic6} alt="" className="img" id="third"/>
                    </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default FooterBox;
