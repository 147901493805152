import React from 'react'
import './Items.css'
const Items = (props) => {
    return (
        <div>
            <div className="prods">
           
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 distance">
                <img src={props.image} alt=""/>
                <h6>{props.name}</h6>
                <p>${props.price}</p>
                </div>
                </div>
                </div>
               
          
    )
}

export default Items;
